import type { NextPage } from 'next';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import 'src/AmplifyConfig';
import { BASE_URL } from 'src/constants/baseUrl';
import { META_AUTHOR, META_FB_DOMAIN, META_KEYWORDS } from 'src/constants/meta';
import { staticAssets } from 'src/constants/staticAssets';
import { isProduction } from 'src/utils/utils';
import { LandingPage } from 'src/views/pages/LandingPage';

const AuthGuard = dynamic<any>(() => import('src/views/common/AuthGuard').then((mod) => mod.AuthGuard), {
  ssr: false,
});

const Home: NextPage = () => {
  // const btnRef = useRef<any>(null);

  const baseUrl = isProduction ? BASE_URL.production : `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;

  return (
    <>
      <Head>
        <title>Anotoys | Collectiverse</title>
        <meta name="description" content="Exclusive Celebrity Approved Digital Collectibles - Anotoys Collectiverse" />
        <link rel="icon" href={staticAssets.favicon} />
        <meta property="og:title" content={`Anotoys | Collectiverse`} />
        <meta
          property="og:description"
          content={`Exclusive Celebrity Approved Digital Collectibles - Anotoys Collectiverse`}
        />
        <meta property="og:image" content={`${baseUrl}/images/banner-infographic.png`} />
        <meta property="og:image:width" content="620" />
        <meta property="og:image:height" content="539" />
        <meta property="og:url" content={baseUrl} />
        <meta name="twitter:card" content="summary_large_image"></meta>
        <meta name="twitter:title" content="Anotoys | Collectiverse" />
        <meta
          name="twitter:description"
          content="Exclusive Celebrity Approved Digital Collectibles - Anotoys Collectiverse"
        />
        <meta name="twitter:image" content={`${baseUrl}/images/banner-infographic.png`} />
        <meta name="keywords" content={META_KEYWORDS} />
        <meta name="author" content={META_AUTHOR} />
        <meta name="facebook-domain-verification" content={META_FB_DOMAIN} />
      </Head>
      <AuthGuard>
        {/* Navigation */}
        {/* <Navigation navBgStyle="blackAlpha.300" navFillStyle="white" navBTN="transparentBTN" /> */}
        {/* Navigation */}
        
        {/* Landing Page Viewer */}
        <LandingPage />
        {/* Landing Page Viewer */}

        {/* Footer */}
        {/* <Footer /> */}
        {/* Footer */}
      </AuthGuard>
    </>
  );
};

export default Home;
