import { createStandaloneToast } from '@chakra-ui/react';
import theme from 'src/theme/theme';

export const standAloneThemedToast = (): ReturnType<typeof createStandaloneToast> => {
  return createStandaloneToast({ theme: theme });
};

export const shortenWalletAddress = (address: string) => {
  if (!address) return;
  return `${address.substr(0, 5)}...${address.substr(address.length - 4, address.length - 1)}`;
};

export const shortenContractAddress = (address: string) => {
  if (!address) return;
  return `${address.substr(0, 8)}...${address.substr(address.length - 7, address.length - 1)}`;
};

export const isEmailValid = (email: string) => {
  const emailRegex =
    /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
  if (!email) return false;

  if (email.length > 254) return false;

  var valid = emailRegex.test(email);
  if (!valid) return false;

  // Further checking of some things regex can't handle
  var parts = email.split('@');
  if (parts[0].length > 64) return false;

  var domainParts = parts[1].split('.');
  if (
    domainParts.some(function (part: any) {
      return part.length > 63;
    })
  )
    return false;

  return true;
};

export const groupByProperty = (objectArray: any[], property: string) => {
  return objectArray.reduce((acc, obj) => {
    var key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
};

export const toUpperCaseWord = (val: string) => {
  return val
    .split(' ')
    .map((item) => {
      return `${item[0].toUpperCase()}${item.length > 1 ? item.substr(1, item.length) : ''}`;
    })
    .join(' ');
};

export const isProduction =
  process.env.VERCEL_ENV === 'production' || process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';
