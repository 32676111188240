import axios from 'axios';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { ArtistsStatsHandlerResponse } from '../handlers/artistsStatsHandler';
import { STATS_QUERY_KEY } from './analyticsQueryKeys';

export type UseStatsQueryValue = ArtistsStatsHandlerResponse;
/**
 * Returns all artits stats and total user.
 */

export default function useStatsQuery(
  options?: UseQueryOptions<UseStatsQueryValue>
): UseQueryResult<UseStatsQueryValue> {
  return useQuery<UseStatsQueryValue>(
    [STATS_QUERY_KEY],
    async () => {
      const result = await axios.get(`/api/artists/stats`);
      return result.data;
    },
    {
      refetchOnWindowFocus: true,
      onError: (e: any) => {
        /**
         * @TODO Add logs here
         */
      },
      ...options,
    }
  );
}
