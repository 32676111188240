import {
  Box,
  Flex,
  Text,
  Button,
  Link,
  useDisclosure
} from '@chakra-ui/react';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { FaEnvelope } from 'react-icons/fa';
import { Footer } from 'src/components/Footer/Footer';
import { CollectiverseIcon } from 'src/components/Icons/CollectiverseIcon';
import { GreenArrowIcon } from 'src/components/Icons/GreenArrownIcon';
import { Navigation } from 'src/components/Navigation/Navigation';
import { TeamCard } from 'src/components/Team/TeamCard';
import useStatsQuery from 'src/modules/analytics/query-hooks/useStatsQuery';
import { useAuthStore } from 'src/modules/auth/authStore';
import { useEmailModalStore } from 'src/modules/user/contexts/emailProviderStore';

export const LandingPage: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const auth = useAuthStore((state) => state.me);
  const { setToggleEmailModal } = useEmailModalStore();

  const [Days, setDays] = useState(0);
  const [Hours, setHours] = useState(0);
  const [Minutes, setMinutes] = useState(0);
  const [Seconds, setSeconds] = useState(0);

  const { data, isLoading } = useStatsQuery();

  return (
    <>
      <Navigation navBgStyle="whiteAlpha.400" navFillStyle="black" navBTN="darkBTN" />

      {/* About us header */}
      <Box pos='relative' w='100%' bgColor="white">
        <Box maxW="80rem" m="0 auto" px="1.5rem">
          <Flex alignItems="center">
            <Box
              w={['100%', '100%', '80%', '50%']}
              p={['150px 0 90px', '150px 0 90px', '160px 0 180px', '160px 0 180px']}
              pos="relative"
              zIndex="2"
            >
              <Text fontSize="1.375rem" fontWeight="bold" lineHeight="26px" color="black" mb=".5rem">
                The Collectiverse
              </Text>
              <Text
                fontSize={['2.5rem', '2.5rem', '3rem', '3.75rem']}
                fontWeight="semibold"
                lineHeight={['46px', '46px', '54px', '66px']}
                className="aboutus_header"
                mb='2rem'
              >
                We’re building something great here.
              </Text>

              <Link href="mailto:info@thecollectiverse.com">
                <Button
                  variant='darkBTN'
                  >
                  <Flex alignItems="center">
                    <Text fontWeight="medium" mr=".5rem">
                      Send us an email
                    </Text>
                    <FaEnvelope/>
                  </Flex>
                </Button>
              </Link>

              <Box className="green_arrow">
                <GreenArrowIcon width="100%" height="auto" />
              </Box>
            </Box>
            <Box>
              <Box w={['200px', '200px', '580px', '780px']} pos="absolute" top="0" right="0" zIndex="1">
                <CollectiverseIcon width="100%" height="auto" />
              </Box>
            </Box>
          </Flex>
        </Box>
      </Box>
      {/* About us header */}

      {/* About us meet the team section */}
      <Box w="100%" pos="relative" bgColor="white" p={["0 0 1.5rem","0 0 1.5rem","0 0 1.5rem","0 0 1.5rem"]}>
        <Box maxW="80rem" m="0 auto" px="1.5rem">
          <Box pt={['2rem', '2rem', '3rem', '3rem']}>
            <Text fontSize={['2rem', '2rem', '2rem', '2.25rem']} fontWeight="bold" lineHeight="42px" color="black">
            The Anotoys Team
            </Text>
          </Box>
          
          <Flex flexDirection="row" flexWrap="wrap" justifyContent="space-between">
            <Box w={['100%', '100%', '31%', '31%']} my={['5.75%', '2.75%', '1.75%', '1.75%']}>
              <TeamCard
                teamAvatar="https://cdn.shopify.com/s/files/1/0618/3203/6509/files/OTA-Avatar.png?v=1703139205"
                name="Oscar G. Tan-Abing, Jr."
                position="Chief Executive Officer • Founder"
                shortBio="Oscar G. Tan-Abing, Jr. is the Founder and Chief Executive Officer of TA Prime Group. Founded Anotoys Collebtibles, one of the fastest-growing premium online collectible startups in the Philippines. He is also the President of Shoepplier, Inc., a major shoe supplier to SM Department Stores."
                shortBioOne="A long-time toy and statue collector, he is an entrepreneur with twenty years of experience in retail, merchandise, executive management, and finance."
                shortBioTwo=""
                shortBioThree=""
                linkedIn="https://www.linkedin.com/in/oca168/"
              />
            </Box>

            <Box w={['100%', '100%', '31%', '31%']} my={['5.75%', '2.75%', '1.75%', '1.75%']}>
              <TeamCard
                teamAvatar="https://cdn.shopify.com/s/files/1/0618/3203/6509/files/Simon-James.png?v=1703139205"
                name="Simon James C. Reyes"
                position="Chief Operating Officer"
                shortBio="Simon James C. Reyes is an accomplished and dynamic business leader with an illustrious career in the food and beverage industry. Currently serving as the Business Director of Long John Silver in the Asia Pacific region, he has been instrumental in driving the brand's success and expansion across diverse markets."
                shortBioOne="With a proven track record of excellence, Simon previously held the esteemed positions of International Director at both T.G.I. Fridays and Wendy's in the Asia Pacific, Europe, and the Middle East. During his tenure, he played a pivotal role in spearheading strategic initiatives that propelled these iconic brands to new heights of growth and global prominence."
                shortBioTwo="Simon's visionary leadership and deep understanding of international markets have earned him widespread acclaim and respect in the industry. His ability to navigate complex business landscapes and deliver exceptional results continues to shape the success of renowned food chains in multiple regions in the world."
                shortBioThree="Through his relentless pursuit of excellence, Simon James C. Reyes remains at the forefront of driving innovation and success in the food and beverage industry."
                linkedIn="https://www.linkedin.com/in/simon-james-c-reyes-mba-63a0322a/"
              />
            </Box>

            <Box w={['100%', '100%', '31%', '31%']} my={['5.75%', '2.75%', '1.75%', '1.75%']}>
              <TeamCard
                teamAvatar="https://cdn.shopify.com/s/files/1/0618/3203/6509/files/Cerry-Ann.png?v=1703139206"
                name="Cerry Ann Figueroa"
                position="Chief Business Development Officer"
                shortBio="Cerry Ann Figueroa is a highly accomplished professional with 15 years of experience in the entertainment industry. As the Country Manager for Paramount Consumer Products and Experience in the Philippines and Thailand, she has played a pivotal role in driving brand growth and fostering strategic partnerships."
                shortBioOne="Having worked with prominent brands like Nickelodeon and Disney Philippines, her unwavering commitment to excellence and an MBA from University Canada West have been instrumental in delivering exceptional consumer experiences and leaving a lasting impact on the industry."
                shortBioTwo=""
                shortBioThree=""
                linkedIn="https://www.linkedin.com/in/cerry-ann-figueroa-5524b555/"
              />
            </Box>

            <Box w={['100%', '100%', '31%', '31%']} my={['5.75%', '2.75%', '1.75%', '1.75%']}>
              <TeamCard
                teamAvatar="https://cdn.shopify.com/s/files/1/0618/3203/6509/files/Marco-Andre.png?v=1703139206"
                name="Marco Andre Selorio"
                position="Chief Community Officer"
                shortBio="Marco Selorio is an accomplished entrepreneur and trailblazer in multiple industries. As the visionary Founder of Coinchella, he has been at the forefront of driving innovation in the cryptocurrency realm, bringing together technology and finance to create groundbreaking solutions."
                shortBioOne="In his role as the CEO of MAS Music, he has demonstrated his passion for the music industry, empowering artists and creators with opportunities for growth and success. His impact in the world of entertainment extends further as the Founder of World Supremacy Battlegrounds, a renowned platform that celebrates and elevates street dance culture on a global scale."
                shortBioTwo="Additionally, he is a Co-Founder of Crypto Gaming United, a pioneering initiative that explores the intersection of blockchain technology and gaming, ushering in a new era of immersive gaming experiences."
                shortBioThree=""
                linkedIn="https://www.linkedin.com/in/marco-selorio/"
              />
            </Box>

            {/* <Box w={['100%', '100%', '31%', '31%']} my={['5.75%', '2.75%', '1.75%', '1.75%']}>
              <TeamCard
                teamAvatar="https://cdn.shopify.com/s/files/1/0618/3203/6509/files/Ralph.png?v=1703139206"
                name="Ralph Acabado"
                position="Chief Technology Officer"
                shortBio="Ralph Acabado is a renowned expert in the field of Blockchain Technology. He has been instrumental in leading the creation of cutting-edge solutions, including Pitaka's Blockchain Wallet and Tetrix Link Web3 Identity."
                shortBioOne="As the CTO of Viridian Technologies Inc., Ralph has demonstrated his expertise in harnessing blockchain's potential to transform industries. Under his leadership, the company has achieved significant milestones and advancements in blockchain space and technology."
                shortBioTwo=""
                shortBioThree=""
                linkedIn="https://www.linkedin.com/in/racabado/"
              />
            </Box> */}
            
            <Box w={['100%', '100%', '31%', '31%']} my={['5.75%', '2.75%', '1.75%', '1.75%']}></Box>
          </Flex>

          {/* Adviser */}
          <Box pt={['0', '0', '3rem', '3rem']}>
            <Text fontSize={['2rem', '2rem', '2rem', '2.25rem']} fontWeight="bold" lineHeight="42px" color="black">
            Our Trusted Advisors
            </Text>
          </Box>
          <Flex flexDirection="row" flexWrap="wrap" justifyContent="space-between">
            <Box w={['100%', '100%', '31%', '31%']} my={['5.75%', '2.75%', '1.75%', '1.75%']}>
              <TeamCard
                teamAvatar="https://cdn.shopify.com/s/files/1/0618/3203/6509/files/David-Miles-_Uslan-v1.png?v=1703139226"
                name="David Miles Uslan"
                position="Advisor | President, Soulgem Entertainment, LLC | Special Consultant, Sony Pictures Entertainment"
                shortBio='Armed with a passion for comic books, history, and creative development, David Uslan went to work for his mentor and father, Michael Uslan, where he assisted in developing and producing films including Batman Begins, The Dark Knight, The Dark Knight Rises, Constantine, Catwoman, National Treasure, and many more.'
                shortBioOne=""
                shortBioTwo=""
                shortBioThree=""
                linkedIn="https://www.linkedin.com/in/david-miles-uslan-165653b/"
              />
            </Box>
            <Box w={['100%', '100%', '31%', '31%']} my={['5.75%', '2.75%', '1.75%', '1.75%']}>
              <TeamCard
                teamAvatar="https://cdn.shopify.com/s/files/1/0618/3203/6509/files/Atty-Ma-Theresa-v1.png?v=1703139225"
                name="Atty. Maria Theresa Baja Ramos"
                position="Advisor | EX-Ericsson AB Qatar and Ericsson Indonesia Head of Legal | Tech Venture Builder | Technology Legal Counsel"
                shortBio=''
                shortBioOne=""
                shortBioTwo=""
                shortBioThree=""
                linkedIn=""
              />
            </Box>
            <Box w={['100%', '100%', '31%', '31%']} my={['5.75%', '2.75%', '1.75%', '1.75%']}>
              <TeamCard
                teamAvatar="https://cdn.shopify.com/s/files/1/0618/3203/6509/files/alexander.png?v=1703142116"
                name="Alexander Apollo Arellano"
                position="Managing Partner, Wissen Solomon Consulting | Chief Strategy Officer, Glades Group of Companies | Deals & Corporate Finance"
                shortBio='PricewaterhouseCoopers SG and PH | 16+ years of experience in M&A, strategy, valuation, post M&A integration in SEA and China'
                shortBioOne=""
                shortBioTwo=""
                shortBioThree=""
                linkedIn=""
              />
            </Box>
            <Box w={['100%', '100%', '31%', '31%']} my={['5.75%', '2.75%', '1.75%', '1.75%']}>
              <TeamCard
                teamAvatar="https://cdn.shopify.com/s/files/1/0618/3203/6509/files/kate-hancock-v1.png?v=1703139225"
                name="Kate Hancock"
                position="Metaverse XYZ CEO, Harvard Business School Web3 Thought Leader"
                shortBio='Kate Hancock is a self-made entrepreneur with a history of establishing successful businesses across various industries including eCommerce, hospitality, real estate, and beauty. She is also a member of the advisory board for various tech projects. With a starting capital of only $20, Kate grew her first company to over a million dollars in revenue and 8 figures in total online sales.'
                shortBioOne='Recognized for her expertise and influence in the field of emerging web 3.0, Kate has been named one of the Top 100 Women of the Future, a Harvard Business School Web3.0 Speaker, and a Global Leadership Influencer by the Entrepreneurs Organization. She has also been named one of the Top 8 Asian American Influencers by Tripadvisor. Kate is a contributor to Entrepreneur Magazine and has interviewed successful entrepreneurs such as Joseph Foster, founder of Reebok, Gino Wickman, author of "Traction," and Verne Harnish from Scaling Up. She is also a TEDx speaker and has been a guest expert on CNN International.'
                shortBioTwo=""
                shortBioThree=""
                linkedIn="https://www.linkedin.com/in/pivotqueen/"
              />
            </Box>
            <Box w={['100%', '100%', '31%', '31%']} my={['5.75%', '2.75%', '1.75%', '1.75%']}>
              <TeamCard
                teamAvatar="https://cdn.shopify.com/s/files/1/0618/3203/6509/files/Ken-Nizam-v1.png?v=1703139226"
                name="Ken Nizam"
                position="Advisor | Founder, Asia Token Fund Group | Co-Founder, Block Asia Labs"
                shortBio="Ken N is the Co-Founder & CEO of Southeast Asia leading blockchain media & venture builder AsiaTokenFund. Ken has been in the blockchain & Fintech space since 2017 and was also the COO and CMO of Singapore’s first BNPL (Buy Now Pay Later) Fintech startup. In the early days of crypto in Singapore, Ken was a member of Huobi Global Elite which promoted one of the world’s largest crypto exchanges in Southeast Asia. Ken’s active involvement in the blockchain space won him numerous awards such as Huobi Global Leading Community Award 2018, UCIM world’s best crypto media Award and others. In 2022, Ken has launched a new blockchain incubator and venture builder Block Asia Labs with aims to support traditional business to transit into Web3 , NFTs and Blockchain integration."
                shortBioOne=""
                shortBioTwo=""
                shortBioThree=""
                linkedIn="https://www.linkedin.com/in/kennizam/"
              />
            </Box>
            <Box w={['100%', '100%', '31%', '31%']} my={['5.75%', '2.75%', '1.75%', '1.75%']}>
              <TeamCard
                teamAvatar="https://cdn.shopify.com/s/files/1/0618/3203/6509/files/vincent-shang-v1.png?v=1703139225"
                name="Vincent Shang"
                position="Founder | Venture Capitalist | Crypto Evangelist | Investor | Web3 | Advisor"
                shortBio="A cryptocurrency enthusiast with unwavering conviction in bitcoin, real estate investment advisor, and co-founder of an internet startup; With extensive experience in project incubation, community marketing, business development, and compliance."
                shortBioOne=""
                shortBioTwo=""
                shortBioThree=""
                linkedIn="https://www.linkedin.com/in/vincent-shang-81404470/"
              />
            </Box>
            <Box w={['100%', '100%', '31%', '31%']} my={['5.75%', '2.75%', '1.75%', '1.75%']}>
              <TeamCard
                teamAvatar="https://cdn.shopify.com/s/files/1/0618/3203/6509/files/Brandon-Lee-v1.png?v=1703139225"
                name="Brandon Lee"
                position="Advisor | Founder, Sleep Future | Co-Founder, Block Asia Labs"
                shortBio="Founder and brainchild of SleepFuture. The OG of sleep to earn. Everyone else who claims to be the world's first is fake. SleepFuture is changing the future of sleep through the world's first sleep-to-earn sleep wellness ecosystem."
                shortBioOne="Deep interest in blockchain, cryptocurrency and NFTs. Looking to collaborate with people in the crypto, NFT, sleep and wellness space."
                shortBioTwo=""
                shortBioThree=""
                linkedIn="https://www.linkedin.com/in/brandonleetong/"
              />
            </Box>
            <Box w={['100%', '100%', '31%', '31%']} my={['5.75%', '2.75%', '1.75%', '1.75%']}>
              <TeamCard
                teamAvatar="https://cdn.shopify.com/s/files/1/0618/3203/6509/files/Pei-Han-Chuang-v1.png?v=1703139959"
                name="Pei-Han Chuang"
                position="Advisor | Co-Founder & CEO – Morpheus Labs"
                shortBio="Morpheus Labs is an end-to-end Blockchain-Platform as a Service (BPaaS) solution for innovation and value creation. Its solution powers blockchain application development, offering ready-to-use development tools and applications in an agnostic environment with leading blockchain technologies to facilitate mass adoption."
                shortBioOne=""
                shortBioTwo=""
                shortBioThree=""
                linkedIn="https://www.linkedin.com/in/chuangpeihan/"
              />
            </Box>
            <Box w={['100%', '100%', '31%', '31%']} my={['5.75%', '2.75%', '1.75%', '1.75%']}></Box>
          </Flex>
          {/* <Box textAlign="center" p="4rem 0 6rem">
            <Text
              fontSize={['1.5rem', '1.75rem', '2.5rem', '2.5rem']}
              fontWeight="semibold"
              lineHeight={['30px', '34px', '46px', '46px']}
              color="black"
              mb="1rem"
            >
              Interested in joining us?
            </Text>
            <Button variant="darkBTN">Checkout Our Career Page</Button>
          </Box> */}
        </Box>
      </Box>
      {/* About us meet the team section */}

      <Footer />
    </>
  );
};
